import useMinWidthMediaQuery from '@hooks/useMinWidthMediaQuery';
import { AboutUs } from '@widgets/about-us';
import { SupportUsModal } from '@widgets/support-us/support-us.modal';
// import { BannerSlider } from '@widgets/banners/banner-slider';
import { HelpStats } from '@widgets/help-stats';
import { HowToHelp } from '@widgets/how-to-help';
import { OurPartners } from '@widgets/our-partners';
import { ShopsPartners } from '@widgets/our-partners/shops';
import { Promo } from '@widgets/promo';
import { Questions } from '@widgets/questions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

// import bannerItems from '@values/banners';

const HomePage = () => {
  const { t } = useTranslation();

  const isLargeScreen = useMinWidthMediaQuery('xl');
  const promoVariant = isLargeScreen ? 'row' : 'column';

  const isTabletAndBiggerScreen = useMinWidthMediaQuery('md');

  return (
    <>
      <h1 className="visually-hidden">{t('you-need-help') + ' ' + t('you-have-help')}</h1>
      {/*<div className="flex text-center justify-center px-4 bg-top bg-no-repeat bg-promo-pattern-mobile xl:bg-promo-pattern-desktop flex-col pt-10.625 pb-3.75">*/}
      {/*  <div className="bg-[#FFFFFF] rounded-3xl p-[56px]">*/}
      {/*    <div className="my-[20px] font-bold text-[32px]">*/}
      {/*      Важливі зміни в роботі платформи!*/}
      {/*    </div>*/}
      {/*    <div className="my-[20px]">*/}
      {/*      Міністерство соціальної політики України та ДП «Інформаційно-обчислювальний центр» повідомляють про завершення роботи платформи єДопомога.*/}
      {/*    </div>*/}
      {/*    <div className="my-[20px]">*/}
      {/*      З <strong> 1 грудня 2024 року</strong>  на платформі більше не можна залишати нові заявки на допомогу чи*/}
      {/*      відгукуватися на них. Благодійники, які мають нерозподілені сертифікати, можуть розподілити їх до 1 січня*/}
      {/*      2025 року. Це рішення пов’язане з переходом на нові, більш ефективні механізми допомоги, впроваджені*/}
      {/*      міжнародними організаціями та Міністерством соціальної політики України.*/}
      {/*    </div>*/}
      {/*    <div className="my-[20px]">*/}
      {/*      Дякуємо за вашу довіру та підтримку!*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {isTabletAndBiggerScreen && <Promo variant={promoVariant} />}
      <HowToHelp />
      {/* Hidden as unnecessary */}
      {/* <BannerSlider items={bannerItems} className="mb-3.75 md:mb-5.875 lg:mb-7.5" /> */}
      <Questions />
      <HelpStats />
      <OurPartners />
      <ShopsPartners />
      <AboutUs />
      <SupportUsModal />
    </>
  );
};

export default HomePage;
